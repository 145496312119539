import { Button, Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import { DialogProps } from '@mui/material/Dialog/Dialog'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import {
  AppRoute,
  PaymentSystem,
  getPaymentSetting,
  portalUiUrl,
} from 'assets/const'
import { useMediaSize } from 'hooks/useMediaSize'
import s from './StripeDialog.module.scss'

interface Props {
  walletType: string | undefined
  connectedAccountId: string | undefined
  isOperationAllowed?: boolean
}

const StripeDialog: FC<Props & Omit<DialogProps, 'open'>> = ({
  walletType,
  connectedAccountId,
  isOperationAllowed = true,
  ...rest
}) => {
  const { isMobileDevice } = useMediaSize()
  const isOpen =
    walletType === getPaymentSetting[PaymentSystem.STRIPE] &&
    !(connectedAccountId && isOperationAllowed)

  const requestedAction = connectedAccountId ? 'Configure' : 'Create'

  return (
    <Dialog
      {...rest}
      open={isOpen}
      scroll={'body'}
      PaperProps={{
        style: { borderRadius: '20px' },
      }}
    >
      <Box className={s.modal}>
        <Typography>
          {`Please ${requestedAction.toLowerCase()} a STRIPE account to perform wallet operations`}
        </Typography>
        <Grid
          item
          xs={12}
          container
          gap={2}
          sx={{
            mt: '20px',
            justifyContent: isMobileDevice ? 'center' : 'space-around',
          }}
        >
          <Button
            LinkComponent='a'
            href={`${portalUiUrl}${AppRoute.Profile}/${AppRoute.StripeProfile}?returnUrl=${window.location.href}`}
            variant='contained'
            fullWidth={isMobileDevice}
          >
            {requestedAction}
          </Button>

          <Link to='/'>
            <Button variant='outlined' fullWidth={isMobileDevice}>
              Cancel
            </Button>
          </Link>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default StripeDialog
