import { SxProps, Theme } from '@mui/material'

import Vector from 'assets/images/Vector.png'


const container: SxProps = {
  minWidth: '487px',
}

const tabsWrapper: SxProps = {
  height: { xs: '303px', sm: '350px' },
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
}

const walletCard: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mr: '12px',
  height: '52px',
  width: '82px',
  borderRadius: '8px',
  background: (theme) =>
    `url(${Vector}) no-repeat right, ${theme.palette.gray.main}`,
}

const getWalletButton = (isActive: boolean): SxProps => ({
  p: { sm: '20px 24px' },
  minHeight: { xs: '77px', sm: '92px' },
  maxWidth: '100%',
  justifyContent: 'flex-start',
  border: '1px transparent solid',
  borderRadius: '8px',
  backgroundColor: 'transparent' ,
  borderColor: isActive ? 'primary.main' : 'transparent',
  ':hover': {
    bgcolor: 'primary.light',
  }
})

const header: SxProps = {
  fontSize: '18px',
  fontWeight: '600',
  textAlign: 'center'
}

const walletName: SxProps<Theme> = {
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: { xs: '22px', sm: '44px', md: '22px' },
  display: 'inline-block',
  color: (theme) => theme.palette.primary.main,

}

const cardCurrency: SxProps = {
  fontSize: '24px' ,
  fontWeight: '600',
}

const closeButton = {
  position: 'absolute',
  top: { xs: '12px', sm: '24px' },
  right: { xs: '12px', sm: '24px' },
}

const submitButton = {
  mt: '24px',
  width: '100%',
}

const Styles = {
  container,
  walletCard,
  tabsWrapper,
  getWalletButton,
  header,
  closeButton,
  submitButton,
  walletName,
  cardCurrency,
}

export default Styles
