import { FC, SyntheticEvent, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import {Box, Button, Tab, Tabs} from '@mui/material'

import CurrencyPanel from './CurrencyPanel'
import Modal from 'components/Modal'

import { useAddWalletMutation } from 'services/wallets'
import { useFetchAllCurrenciesQuery } from 'services/dictionaries'
import { getCurrenciesByType } from 'utils/helpers'
import { getDefaultName } from 'utils/text'

import s from './styles'


interface Props {
  handleClose:()=>void
}
const AddWalletModal:FC<Props> = ({handleClose}) => {
  const CRYPTO_LABEL = 'Crypto'
  const FIAT_LABEL = 'National'

  const [value, setValue] = useState<number>(1)
  const [activeWallet, setActiveWallet] = useState('')

  const [addWallet, {isLoading }] = useAddWalletMutation()
  const { data: currencies = [], isLoading: isLoadingCurrency } = useFetchAllCurrenciesQuery()

  const [, setSearchParams] = useSearchParams()

  const handleActiveWalletChange = (currency: string) => {
    setActiveWallet(currency)
  }

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const onSubmit = () => {
    addWallet({
    currency: activeWallet,
    name: getDefaultName(activeWallet),
    isDefault: false,
    walletType: 'stripe',
    }).unwrap()
      .then((resp)=> {
        handleClose()
        setSearchParams(prevParams => {
          const params = new URLSearchParams(prevParams)
          params.set('wallet', resp.id)
          params.set('currency', resp.currency)
          params.set('excludeEmpty', 'false')
          return params
        })
      })
  }

  return (
    <Modal
      open
      onClose={handleClose}
      handleClose={handleClose}
      title='Add new wallet'
    >
        <Box sx={s.container}>
          <Box sx={{ width: '100%' }}>
            <Tabs
              centered
              variant='fullWidth'
              value={value}
              onChange={handleTabChange}
              sx={{ mb: 3, borderBottom: 1, borderColor: 'divider' }}
            >
              <Tab label={CRYPTO_LABEL} />
              <Tab label={FIAT_LABEL} />
            </Tabs>
            <CurrencyPanel
              isLoading={isLoadingCurrency}
              data={getCurrenciesByType(currencies).crypto}
              label={CRYPTO_LABEL}
              value={value}
              index={0}
              activeWallet={activeWallet}
              handleButtonClick={handleActiveWalletChange}
            />
            <CurrencyPanel
              isLoading={isLoadingCurrency}
              data={getCurrenciesByType(currencies).fiat}
              label={FIAT_LABEL}
              value={value}
              index={1}
              activeWallet={activeWallet}
              handleButtonClick={handleActiveWalletChange}
            />
          </Box>

          <Button
          variant='contained'
            onClick={onSubmit}
            sx={s.submitButton}
            disabled={isLoading || !activeWallet}
          >
            Create
          </Button>
        </Box>
    </Modal>
  )
}

export default AddWalletModal