import { Backdrop } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

const Preloader = () => {
  return (
    <Backdrop open invisible sx={{ zIndex: 10 }}>
      <CircularProgress />
    </Backdrop>
  )
}

export default Preloader
