// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/swiper/swiper.min.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/swiper/modules/pagination/pagination.min.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../node_modules/react-toastify/dist/ReactToastify.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root {\n  --primary: #262626;\n  --indigo: #7b18f5;\n  --white: #ffffff;\n  --text-gray: #79747E;\n  --main-bg: #f6f6f6;\n  --text-light-gray: #6d6d6d;\n}\n\nhtml,\nbody {\n  padding: 0;\n  margin: 0;\n  font-family: 'Poppins', sans-serif;\n  /* font-size: 14px;\n  font-weight: 700; */\n  box-sizing: border-box;\n  background-color: #f5f5f5 !important;\n  min-height: 100vh;\n}\n\na {\n  color: inherit;\n  text-decoration: none;\n}\n\n.MuiTypography-root .MuiDialogTitle-root {\n  padding: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAMA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,gBAAgB;EAChB,oBAAoB;EACpB,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;;EAEE,UAAU;EACV,SAAS;EACT,kCAAkC;EAClC;qBACmB;EACnB,sBAAsB;EACtB,oCAAoC;EACpC,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,qBAAqB;AACvB;;AAEA;EACE,UAAU;AACZ","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\n@import 'swiper/css';\n@import 'swiper/css/pagination';\n@import 'react-toastify/dist/ReactToastify.css';\n\n:root {\n  --primary: #262626;\n  --indigo: #7b18f5;\n  --white: #ffffff;\n  --text-gray: #79747E;\n  --main-bg: #f6f6f6;\n  --text-light-gray: #6d6d6d;\n}\n\nhtml,\nbody {\n  padding: 0;\n  margin: 0;\n  font-family: 'Poppins', sans-serif;\n  /* font-size: 14px;\n  font-weight: 700; */\n  box-sizing: border-box;\n  background-color: #f5f5f5 !important;\n  min-height: 100vh;\n}\n\na {\n  color: inherit;\n  text-decoration: none;\n}\n\n.MuiTypography-root .MuiDialogTitle-root {\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
