import { Button, Card, Typography } from '@mui/material'

import s from '../styles'

interface Props {
  currency: string
  isActiveWallet: boolean
  handleClick: () => void
}

const TableButton = ({ currency, isActiveWallet, handleClick }: Props) => {
  return (
    <Button
      role={'div'}
      onClick={handleClick}
      sx={s.getWalletButton(isActiveWallet)}
      startIcon={
        <Card sx={s.walletCard}>
          <Typography sx={s.cardCurrency}>{currency}</Typography>
        </Card>
      }
    >
      <Typography sx={s.walletName}>{currency}</Typography>
      &nbsp;
      <Typography sx={{...s.walletName, color: 'text.primary'}}>wallet</Typography>
    </Button>
  )
}

export default TableButton
