import { Box, CircularProgress, Typography } from '@mui/material'

import ScrollableBox from 'components/ScrollableBox'
import TabPanel from 'components/TablePanel'
import TableButton from '../TableButton'

import s from '../styles'

interface Props {
  isLoading: boolean
  data: string[] | undefined
  label: string
  value: number
  index: number
  activeWallet: string
  handleButtonClick: (currency: string) => void
}

const CurrencyPanel = ({
  isLoading,
  data,
  label,
  value,
  index,
  activeWallet,
  handleButtonClick,
}: Props) => {
  return (
    <TabPanel value={value} index={index} aria-labelledby={`tabpanel-${label}`}>
      {isLoading ? (
        <Box
          sx={{
            ...s.tabsWrapper,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ScrollableBox sx={s.tabsWrapper}>
          {data?.length ? (
            data.map((currency, i) => (
              <TableButton
                key={currency + i}
                currency={currency}
                isActiveWallet={activeWallet === currency}
                handleClick={() => handleButtonClick(currency)}
              />
            ))
          ) : (
            <Typography alignSelf='center'>{`${label} wallets are not available`}</Typography>
          )}
        </ScrollableBox>
      )}
    </TabPanel>
  )
}

export default CurrencyPanel