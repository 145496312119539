// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".StripeDialog_paper__Kta\\+\\+ {\n  border-radius: 20px;\n}\n\n.StripeDialog_modal__xFbRm {\n  padding: 40px 24px 24px;\n  position: relative;\n  box-sizing: border-box;\n  color: #000000;\n  max-width: 920px;\n  text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/components/StripeDialog/StripeDialog.module.scss"],"names":[],"mappings":"AAAA;EACE,mBAAA;AACF;;AAEA;EACE,uBAAA;EACA,kBAAA;EACA,sBAAA;EACA,cAAA;EACA,gBAAA;EACA,kBAAA;AACF","sourcesContent":[".paper {\n  border-radius: 20px;\n}\n\n.modal {\n  padding: 40px 24px 24px;\n  position: relative;\n  box-sizing: border-box;\n  color: #000000;\n  max-width: 920px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paper": "StripeDialog_paper__Kta++",
	"modal": "StripeDialog_modal__xFbRm"
};
export default ___CSS_LOADER_EXPORT___;
