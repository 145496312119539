import { Box, Button, Grid, Typography } from '@mui/material'
import { FC, useMemo } from 'react'
import { FieldValues, useForm } from 'react-hook-form'

import Modal from 'components/Modal'
import Select from 'components/Select'
import TextInput from 'components/TextInput'

import { useMediaSize } from 'hooks/useMediaSize'
import { PSDefaultTemplate, PSTemplateRequest } from 'types/template'
import { capitalize } from 'utils/text'


interface Props {
  isFetching: boolean
  template: PSDefaultTemplate
  handleClose: () => void
  handleSave: (template: PSTemplateRequest) => void
}

const EditDetails: FC<Props> = ({
  template,
  isFetching,
  handleClose,
  handleSave,
}) => {
  const { isMobileDevice } = useMediaSize()
  const minValidAmount = 0
  const fields = useMemo(
    () =>
      template.paymentFormDefinition.slice().sort((a, b) => a.order - b.order),
    [template]
  )

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onBlur',
    defaultValues: { ...template.values, name: template.name },
  })

  const onSubmit = async (formData: FieldValues) => {
    const { name, ...paymentFormDefinition } = formData
    const templateToSave: PSTemplateRequest = {
      name,
      id: template.id,
      code: template.code,
      paymentFormDefinition,
    }

    await handleSave(templateToSave)
  }

  return (
    <Modal handleClose={handleClose} open title='Edit details'>
      <Box component={'form'} noValidate onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <TextInput
              required
              control={control}
              name='name'
              label='Name'
              errors={errors}
              rules={{ required: 'The field can’t be empty' }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
              Template details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)' },
                gap: { xs: '15px', sm: '25px' },
                mb: '15px',
              }}
            >
              {fields?.map((field) => {
                const options = field.values.split('|').map((value) => ({
                  label: capitalize(value),
                  value,
                }))

                return options.length > 1 ? (
                  <Select
                    name={field.fieldName}
                    key={field.fieldName}
                    options={options}
                    label={field.label || capitalize(field.fieldName)}
                    control={control}
                    errors={errors}
                    required={!field.optional}
                    rules={{
                      required: !field.optional
                        ? 'The field can’t be empty'
                        : '',
                    }}
                    InputProps={{ sx: { backgroundColor: '#FFFFFF' } }}
                  />
                ) : (
                  <TextInput
                    key={field.fieldName}
                    control={control}
                    name={field.fieldName}
                    label={field.label || capitalize(field.fieldName)}
                    errors={errors}
                    required={!field.optional}
                    FormHelperTextProps={{
                      sx: {
                        '&.MuiFormHelperText-root': {
                          height: { xs: 'auto', sm: 0 },
                          mb: { xs: '-10px', sm: 'auto' },
                        },
                      },
                    }}
                    InputProps={{ sx: { backgroundColor: '#FFFFFF' } }}
                    rules={{
                      required: !field.optional
                        ? 'The field can’t be empty'
                        : '',
                      pattern: {
                        value: new RegExp(field.pattern),
                        message: 'Enter a valid value',
                      },
                      validate: {
                        isNumber: (v) => {
                          if (field.fieldType === 'string') return true
                          return !isNaN(v) || 'Enter a valid value'
                        },
                        isValidAmount: (v) => {
                          if (field.fieldName !== 'amount') return true
                          if (v < minValidAmount)
                            return `Amount should be bigger or equal then ${minValidAmount}`
                        },
                      },
                    }}
                  />
                )
              })}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{ justifyContent: isMobileDevice ? 'center' : 'flex-start', mt: '12px' }}
        >
          <Button
            variant='contained'
            type='submit'
            disabled={isFetching || !isValid}
            sx={{width: {xs: '100%', sm: '200px'}}}
          >
            Save
          </Button>
        </Grid>
      </Box>
    </Modal>
  )
}

export default EditDetails