import { Box, Button, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import Select from 'components/Select'
import TextInput from 'components/TextInput'

import { useMediaSize } from 'hooks/useMediaSize'
import { useTemplateFields } from 'hooks/useTemplateFields'
import { countDecimals, getWithdrawData } from 'utils/helpers'
import { capitalize } from 'utils/text'

import Preloader from 'components/Preloader'
import { Currency } from 'types/currency'
import { WithdrawReqData } from 'types/requests'
import {
  PaymentFormDefinitionModel,
  PaymentFormFieldValues,
  PSDefaultTemplate,
} from 'types/template'
import { Wallet } from 'types/wallet'


interface Props {
  handleWithdrawSubmit: (data: WithdrawReqData) => void
  activeTemplate: PSDefaultTemplate
  wallet: Wallet
  currency: Currency
  isFetching: boolean
  handleCreateTemplate: (templateToSave: PaymentFormDefinitionModel) => void
}

const Details = ({
  handleWithdrawSubmit,
  activeTemplate,
  wallet,
  currency,
  isFetching,
  handleCreateTemplate,
}: Props) => {
  const { isPhone } = useMediaSize()
  const MIN_CRYPTO_AMOUNT = 0.0001
  const MIN_FIAT_AMOUNT = 1
  const { isMobileDevice } = useMediaSize()
  const { fields } = useTemplateFields(activeTemplate)
  const minValidAmount = currency.isCrypto ? MIN_CRYPTO_AMOUNT : MIN_FIAT_AMOUNT
  const minValidAmountText = currency.isCrypto
    ? MIN_CRYPTO_AMOUNT.toString().padEnd(currency.maxDecimals + 2, '0')
    : MIN_FIAT_AMOUNT.toString()
        .concat('.')
        .padEnd(currency.maxDecimals + 2, '0')

  const {
    control,
    formState: { errors, isValid },
    trigger,
    getValues,
    handleSubmit,
  } = useForm({
    mode: 'onBlur',
    defaultValues: activeTemplate?.values,
  })

  const onSubmit = (formData: PaymentFormFieldValues) => {
    const { save, ...data } = formData
    const withdrawData = getWithdrawData({
      formData: data,
      fields,
      wallet,
      psCode: activeTemplate.psCode,
    })

    handleWithdrawSubmit({ ...withdrawData })
  }

  useEffect(() => {
    if (activeTemplate?.values?.amount) {
      trigger('amount')
    }
  }, [])

  return (
    <>
      {isFetching && <Preloader />}
      <Box component='form' noValidate onSubmit={handleSubmit(onSubmit)}>
        <Typography variant={isPhone ? 'h4' : 'h3'} sx={{ mb: 3 }}>
          Please specify withdrawal details
        </Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: { xs: '1fr', sm: 'repeat(2, 1fr)' },
            gap: { xs: '16px', sm: '24px' },
            mb: '15px',
          }}
        >
          {fields?.map((field, i) => {
            const options = field.values.split('|').map((value) => ({
              label: capitalize(value),
              value,
            }))

            return options.length > 1 ? (
              <Select
                name={field.fieldName}
                key={field.fieldName}
                options={options}
                label={field.label || capitalize(field.fieldName)}
                control={control}
                errors={errors}
                required={!field.optional}
                rules={{
                  required: !field.optional ? 'The field can’t be empty' : '',
                }}
                InputProps={{ sx: { backgroundColor: '#FFFFFF' } }}
              />
            ) : (
              <TextInput
                key={field.fieldName}
                control={control}
                name={field.fieldName}
                label={field.label || capitalize(field.fieldName)}
                errors={errors}
                required={!field.optional}
                FormHelperTextProps={{
                  sx: {
                    '&.MuiFormHelperText-root': {
                      height: { xs: 'auto', sm: 0 },
                      mb: { xs: '-10px', sm: 'auto' },
                    },
                  },
                }}
                InputProps={{ sx: { backgroundColor: '#FFFFFF' } }}
                rules={{
                  required: !field.optional ? 'The field can’t be empty' : '',
                  pattern: {
                    value: new RegExp(field.pattern),
                    message: 'Enter a valid value',
                  },
                  validate: {
                    isNumber: (v) => {
                      if (field.fieldType === 'string') return true
                      return !isNaN(v) || 'Enter a valid value'
                    },
                    isValidAmount: (v) => {
                      if (field.fieldName !== 'amount') return true
                      if (v < minValidAmount)
                        return `Amount should be bigger or equal then ${minValidAmountText}`
                    },
                    isMaxDecimals: (v) => {
                      if (field.fieldName !== 'amount' || !currency.maxDecimals)
                        return true
                      if (countDecimals(v) > currency.maxDecimals)
                        return `Decimal part should contain no more than ${currency.maxDecimals} digits`
                    },
                    isEnoughBalance: (v) => {
                      if (field.fieldName !== 'amount') return true
                      if (Number(v) > Number(wallet.balance))
                        return `Please make a deposit and try again. Available balance: ${wallet.balance}`
                    },
                  },
                }}
              />
            )
          })}
        </Box>
        {/* <Box mb='15px'>
          <Checkbox control={control} name='save' label='Save As Template' />
        </Box> */}
        <Box display='flex' gap={3} mt={isPhone ? 3 : 4}>
            <Button
              variant='outlined'
              disabled={!isValid}
              fullWidth={isMobileDevice}
              onClick={() => handleCreateTemplate(getValues())}
              sx={{flex: 1}}
            >
              Save as template
            </Button>
            <Button
              variant='contained'
              type='submit'
              disabled={!isValid || isFetching}
              fullWidth={isMobileDevice}
              sx={{flex: 1}}
            >
              Continue
            </Button>
        </Box>
      </Box>
    </>
  )
}

export default Details